import React, { useMemo, useState } from 'react';
import { useTable, useSortBy } from 'react-table';
import '../styles/TableViewer.css';

// Column-specific formatting function (unchanged)
const formatCellValue = (value, formatType) => {
    if (value && typeof value === 'object' && value.seconds && value.nanoseconds) {
        return new Date(value.seconds * 1000).toLocaleString();
    }
    if (typeof value === 'number') {
        if (isNaN(value)) return '0';
        switch (formatType) {
            case 'currency':
                return `$${parseFloat(value).toFixed(2)}`;
            case 'float':
                return parseFloat(value).toFixed(2);
            case 'int':
                return parseInt(value, 10);
            case 'percentage':
                return `${(parseFloat(value) * 100).toFixed(2)}%`;
            default:
                return value;
        }
    }
    return value;
};

const TableViewer = ({ dataKey, colFormatDict, timestamp }) => {
    // Filter data by the provided timestamp
    const filteredData = useMemo(() => {
        if (!timestamp) return dataKey;
        return dataKey.filter((row) => row['week_summary'] === timestamp);
    }, [dataKey, timestamp]);

    // Build a list of all columns from colFormatDict
    const availableColumns = useMemo(() => {
        return Object.keys(colFormatDict).map((key) => ({
            key,
            ...colFormatDict[key],
        }));
    }, [colFormatDict]);

    // Determine the initial set of visible columns (i.e., not marked "skip")
    const initialVisible = useMemo(() => {
        return availableColumns
            .filter((col) => !col.skip)
            .map((col) => col.key);
    }, [availableColumns]);

    // Track currently visible columns, and modal open state
    const [visibleColumns, setVisibleColumns] = useState(initialVisible);
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Toggle a single column's visibility
    const toggleColumn = (colKey) => {
        if (visibleColumns.includes(colKey)) {
            setVisibleColumns(visibleColumns.filter((id) => id !== colKey));
        } else {
            setVisibleColumns([...visibleColumns, colKey]);
        }
    };

    // Restore columns to the initial default
    const restoreDefault = () => {
        setVisibleColumns(initialVisible);
    };

    // Build the react-table columns based on visibleColumns
    const columns = useMemo(() => {
        if (!filteredData || filteredData.length === 0) return [];
        const cols = availableColumns
            .filter((col) => visibleColumns.includes(col.key))
            .map(({ key, ...formatInfo }) => {
                const justify = formatInfo.just || 'left';
                const header = formatInfo.name || key;
                let sortType = 'alphanumeric';
                if (['currency', 'float', 'int', 'percentage'].includes(formatInfo.type)) {
                    sortType = 'basic';
                }
                return {
                    Header: header,
                    accessor: key,
                    Cell: ({ value }) => formatCellValue(value, formatInfo.type || 'default'),
                    justify,
                    pos: formatInfo.pos || null,
                    sortType,
                };
            });
        // Sort columns by 'pos' if present
        cols.sort((a, b) => {
            if (a.pos !== null && b.pos !== null) return a.pos - b.pos;
            if (a.pos !== null) return -1;
            if (b.pos !== null) return 1;
            return 0;
        });
        return cols;
    }, [filteredData, availableColumns, visibleColumns]);

    // Initialize react-table with sorting
    const tableInstance = useTable(
        {
            columns,
            data: filteredData || [],
            initialState: {
                sortBy: [
                    {
                        id: 'gp_per_hour', // or any other default-sorted column
                        desc: true,
                    },
                ],
            },
        },
        useSortBy
    );

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

    // Form submission (Enter key or "Save") closes modal
    const handleModalSubmit = (e) => {
        e.preventDefault();
        setIsModalOpen(false);
    };

    if (!filteredData || filteredData.length === 0) {
        return <div>No Data Available</div>;
    }

    return (
        <div className="table-wrapper">
            {/* Button to open the column selection modal */}
            <button className="column-selector-button" onClick={() => setIsModalOpen(true)}>
                Select Columns
            </button>

            {isModalOpen && (
                <div className="modal-overlay" onClick={() => setIsModalOpen(false)}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <div className="popup-header">
                            <h3>Select Columns</h3>
                            <button className="close-button" onClick={() => setIsModalOpen(false)}>
                                ×
                            </button>
                        </div>
                        {/* We wrap our grid in a <form> so pressing Enter triggers handleModalSubmit */}
                        <form onSubmit={handleModalSubmit}>
                            {/* 3-column grid with clickable items */}
                            <div className="modal-columns-grid">
                                {availableColumns
                                    .sort((a, b) => {
                                        if (a.pos !== null && b.pos !== null) return a.pos - b.pos;
                                        if (a.pos !== null) return -1;
                                        if (b.pos !== null) return 1;
                                        return 0;
                                    })
                                    .map((col) => {
                                        const selected = visibleColumns.includes(col.key);
                                        return (
                                            <div
                                                key={col.key}
                                                className={`modal-grid-item ${selected ? 'selected' : ''}`}
                                                onClick={() => toggleColumn(col.key)}
                                            >
                                                {col.name || col.key}
                                            </div>
                                        );
                                    })}
                            </div>
                            <div className="modal-buttons">
                                <button type="button" onClick={restoreDefault}>
                                    Default
                                </button>
                                <button type="submit">Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            <div className="table-container">
                <table {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()} key={`header-group-${headerGroup.id}`}>
                                {headerGroup.headers.map((column) => (
                                    <th
                                        key={`header-${column.id}`}
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        style={{
                                            textAlign: column.justify,
                                            border: '1px solid black',
                                        }}
                                    >
                                        {column.render('Header')}
                                        <span>
                                            {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} key={`row-${row.id}`}>
                                    {row.cells.map((cell) => (
                                        <td
                                            key={`cell-${row.id}-${cell.column.id}`}
                                            {...cell.getCellProps()}
                                            style={{
                                                textAlign: cell.column.justify,
                                                border: '1px solid black',
                                            }}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    ))}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default TableViewer;
