import React, { useContext, useEffect, useState, useMemo } from 'react';
import { DataContext } from '../components/DataContext';
import { getAuth } from 'firebase/auth';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { firestore } from '../utils/firebaseConfig';
import '../styles/Dashboard.css';

const Dashboard = () => {
    const { employeeKpis, loadingEmployee } = useContext(DataContext);
    const [userFullName, setUserFullName] = useState('');
    const [periods, setPeriods] = useState([]);
    const [selectedRange, setSelectedRange] = useState(30); // 30 / 60 / 90 days

    // Fetch the logged-in user's name from Firestore
    useEffect(() => {
        const fetchUserFullName = async () => {
            const auth = getAuth();
            const user = auth.currentUser;
            if (user) {
                const userEmail = user.email;
                const usersCollection = collection(firestore, 'users');
                const userQuery = query(usersCollection, where('email', '==', userEmail));
                const querySnapshot = await getDocs(userQuery);

                if (!querySnapshot.empty) {
                    const userDoc = querySnapshot.docs[0].data();
                    setUserFullName(`${userDoc.firstName} ${userDoc.lastName}`);
                } else {
                    // Fallback to email if no matching user document found
                    setUserFullName(userEmail);
                }
            }
        };
        fetchUserFullName();
    }, []);

    // Once we have userFullName, fetch all available week summaries for that user
    useEffect(() => {
        const fetchPeriods = async () => {
            const kpisCollection = collection(firestore, 'employee_kpis');
            const kpisSnapshot = await getDocs(kpisCollection);

            // Filter to only the current user's data with a week_summary
            const userKpis = kpisSnapshot.docs
                .map((doc) => doc.data())
                .filter((data) => data.employee === userFullName && data.week_summary);

            // Extract unique week_summary values
            const weekSummaries = Array.from(new Set(userKpis.map((item) => item.week_summary)));

            // Sort them descending by the END date
            weekSummaries.sort((a, b) => extractEndDate(b) - extractEndDate(a));
            setPeriods(weekSummaries);
        };

        if (userFullName) {
            fetchPeriods();
        }
    }, [userFullName]);

    // Parse the "end date" from "[M, D, YYYY] to [M, D, YYYY]" (ignoring the first date)
    const extractEndDate = (weekString) => {
        const cleaned = weekString.replace('Period: ', '');
        const match = cleaned.match(/\[(\d+), (\d+), (\d+)\] to \[(\d+), (\d+), (\d+)\]/);
        if (!match) return new Date('1970-01-01'); // fallback
        // ignore m1, d1, y1, just use m2, d2, y2
        const [, , , , m2, d2, y2] = match;
        return new Date(`${m2}/${d2}/${y2}`);
    };

    // Format "[M, D, YYYY] to [M, D, YYYY]" into something like "MM.DD.YY - MM.DD.YY"
    const getWeekLabel = (weekString) => {
        const cleanedString = weekString.replace('Period: ', '');
        const match = cleanedString.match(/\[(\d+), (\d+), (\d+)\] to \[(\d+), (\d+), (\d+)\]/);
        if (match) {
            const [, month1, day1, year1, month2, day2, year2] = match;
            const formattedStartDate = `${month1.padStart(2, '0')}.${day1.padStart(2, '0')}.${year1.slice(-2)}`;
            const formattedEndDate = `${month2.padStart(2, '0')}.${day2.padStart(2, '0')}.${year2.slice(-2)}`;
            return `${formattedStartDate} - ${formattedEndDate}`;
        }
        return weekString;
    };

    // Render a weekly KPI card
    const renderWeekCard = (week) => {
        const kpi = employeeKpis?.find(
            (item) => item.employee === userFullName && item.week_summary === week
        );

        if (!kpi) {
            return (
                <div className="week-summary-card" key={week}>
                    <h2>{getWeekLabel(week)}</h2>
                    <p>No data available.</p>
                </div>
            );
        }

        const hours = kpi.hours ?? 0;
        const sales = kpi.sales ?? 0;
        const ratio = hours > 0 ? (sales / hours) * 100 : 0;

        let tierLabel = 'N/A';
        if (ratio >= 100) tierLabel = 'Six';
        else if (ratio >= 80) tierLabel = 'Five';
        else if (ratio >= 60) tierLabel = 'Four';
        else if (ratio >= 40) tierLabel = 'Three';
        else if (ratio >= 20) tierLabel = 'Two';
        else if (ratio >= 0) tierLabel = 'One';

        return (
            <div className="week-summary-card" key={week}>
                <h2>{getWeekLabel(week)}</h2>
                <p>
                    <strong>Tier:</strong> {tierLabel} ( {ratio.toFixed(2)} )
                </p>
                <p>
                    <strong>Total GP:</strong>{' '}
                    {kpi.total_gp !== undefined ? `$${kpi.total_gp.toFixed(2)}` : 'N/A'}
                </p>
                <p>
                    <strong>GP / Hour:</strong>{' '}
                    {kpi.gp_per_hour !== undefined ? `$${kpi.gp_per_hour.toFixed(2)}` : 'N/A'}
                </p>
                <p>
                    <strong>Estimated Commission:</strong>{' '}
                    {kpi.est_commission !== undefined ? `$${kpi.est_commission.toFixed(2)}` : 'N/A'}
                </p>
                <p>
                    <strong>Sales:</strong> {sales > 0 ? `${sales}` : 'N/A'}
                </p>
                <p>
                    <strong>Repairs:</strong> {kpi.repairs ?? 'N/A'}
                </p>
                <p>
                    <strong>Hours Worked:</strong> {hours > 0 ? `${hours.toFixed(1)} hrs` : 'N/A'}
                </p>
            </div>
        );
    };

    // Compute averages for the selected time range, including "Position"
    const computeAverages = useMemo(() => {
        if (!employeeKpis || !userFullName) return null;

        const now = new Date();
        const rangeMillis = selectedRange * 24 * 60 * 60 * 1000;

        // Filter the user's KPIs to the last X days
        const relevantKpis = employeeKpis.filter((item) => {
            if (item.employee !== userFullName) return false;
            if (!item.week_summary) return false;

            const endDate = extractEndDate(item.week_summary);
            const diff = now - endDate;
            return diff <= rangeMillis && diff >= 0;
        });

        if (!relevantKpis.length) return null;

        let sumPosition = 0;
        let sumSalesBonus = 0;
        let sumTotalGp = 0;
        let sumEstComm = 0;
        let sumSalesCount = 0;
        let sumRepairsCount = 0;
        let sumHours = 0;

        // For each relevant KPI, we compute the user's rank that week based on Sales/Hour
        relevantKpis.forEach((kpi) => {
            // sum up fields
            sumSalesBonus += kpi.sales_bonus || 0;
            sumTotalGp += kpi.total_gp || 0;
            sumEstComm += kpi.est_commission || 0;
            sumSalesCount += kpi.sales || 0;
            sumRepairsCount += kpi.repairs || 0;
            sumHours += kpi.hours || 0;

            // find position in that week's leaderboard
            const week = kpi.week_summary;
            // find all employees for that week
            const allForWeek = employeeKpis.filter((x) => x.week_summary === week);
            // sort them by Sales/Hour descending
            const sortedBySalesHr = [...allForWeek].sort((a, b) => {
                const aHours = a.hours || 0;
                const bHours = b.hours || 0;
                const aRatio = aHours > 0 ? (a.sales || 0) / aHours : 0;
                const bRatio = bHours > 0 ? (b.sales || 0) / bHours : 0;
                return bRatio - aRatio;
            });
            // find the index of this user
            const userIndex = sortedBySalesHr.findIndex((x) => x.employee === userFullName);
            // position is index+1
            const position = userIndex >= 0 ? userIndex + 1 : sortedBySalesHr.length;
            sumPosition += position;
        });

        const count = relevantKpis.length;
        const avgPosition = sumPosition / count;

        // Weighted approach for Sales/Hr and GP/Hr: sum-of-sums
        const gpPerHour = sumHours > 0 ? sumTotalGp / sumHours : 0;
        const salesPerHour = sumHours > 0 ? sumSalesCount / sumHours : 0;

        // We'll do a simple average for the others
        const avgSalesBonus = sumSalesBonus / count;
        const avgTotalGp = sumTotalGp / count;
        const avgEstComm = sumEstComm / count;
        const avgSalesCount = sumSalesCount / count;
        const avgRepairsCount = sumRepairsCount / count;

        return {
            avgPosition,
            salesPerHour,
            avgSalesBonus,
            gpPerHour,
            avgTotalGp,
            avgEstComm,
            avgSalesCount,
            avgRepairsCount,
        };
    }, [employeeKpis, userFullName, selectedRange]);

    // Renders the single "Averages" card
    const renderAveragesCard = () => {
        if (computeAverages === null) {
            return (
                <div className="averages-card">
                    <p>No data found for last {selectedRange} days.</p>
                </div>
            );
        }

        const {
            avgPosition,
            salesPerHour,
            avgSalesBonus,
            gpPerHour,
            avgTotalGp,
            avgEstComm,
            avgSalesCount,
            avgRepairsCount,
        } = computeAverages;

        return (
            <div className="averages-card">
                <h2>Averages (Last {selectedRange} Days)</h2>
                {/* 1) Position */}
                <p>
                    <strong>Position (Avg):</strong>{' '}
                    {Number.isFinite(avgPosition) ? avgPosition.toFixed(2) : 'N/A'}
                </p>
                {/* 2) Sales / Hr */}
                <p>
                    <strong>Sales / Hr:</strong>{' '}
                    {Number.isFinite(salesPerHour) ? salesPerHour.toFixed(2) : 'N/A'}
                </p>
                {/* 3) Sales Bonus */}
                <p>
                    <strong>Sales Bonus (Avg):</strong>{' '}
                    {Number.isFinite(avgSalesBonus) ? `$${avgSalesBonus.toFixed(2)}` : 'N/A'}
                </p>
                {/* 4) GP / Hr */}
                <p>
                    <strong>GP / Hr:</strong>{' '}
                    {Number.isFinite(gpPerHour) ? `$${gpPerHour.toFixed(2)}` : 'N/A'}
                </p>
                {/* 5) Total GP */}
                <p>
                    <strong>Total GP (Avg):</strong>{' '}
                    {Number.isFinite(avgTotalGp) ? `$${avgTotalGp.toFixed(2)}` : 'N/A'}
                </p>
                {/* 6) Est. Commission */}
                <p>
                    <strong>Est. Commission (Avg):</strong>{' '}
                    {Number.isFinite(avgEstComm) ? `$${avgEstComm.toFixed(2)}` : 'N/A'}
                </p>
                {/* 7) # of Sales */}
                <p>
                    <strong># of Sales (Avg):</strong>{' '}
                    {Number.isFinite(avgSalesCount) ? avgSalesCount.toFixed(2) : 'N/A'}
                </p>
                {/* 8) # of Repairs */}
                <p>
                    <strong># of Repairs (Avg):</strong>{' '}
                    {Number.isFinite(avgRepairsCount) ? avgRepairsCount.toFixed(2) : 'N/A'}
                </p>
            </div>
        );
    };

    // Range toggle (30 / 60 / 90 days)
    const renderRangeSelector = () => (
        <div className="range-selector">
            {[30, 60, 90].map((range) => (
                <button
                    key={range}
                    className={range === selectedRange ? 'active' : ''}
                    onClick={() => setSelectedRange(range)}
                >
                    {range} Days
                </button>
            ))}
        </div>
    );

    return (
        <div className="dashboard-container">
            <h1>Welcome, {userFullName}</h1>

            {loadingEmployee ? (
                <p>Loading Employee Data...</p>
            ) : (
                <>
                    {/* Averages Card & Range Selector */}
                    <div className="averages-section">
                        {renderRangeSelector()}
                        {renderAveragesCard()}
                    </div>

                    <h3>Your Weekly Performance</h3>
                    {periods.length === 0 ? (
                        <p>No weekly KPI data found.</p>
                    ) : (
                        <div className="week-carousel">
                            {periods.map((week) => renderWeekCard(week))}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default Dashboard;
