import React, { useContext, useState, useEffect } from 'react';
import { DataContext } from '../components/DataContext';
import TableViewer from './TableViewer';
import { collection, getDocs } from 'firebase/firestore';
import { firestore } from '../utils/firebaseConfig.js';
import '../styles/Employees.css';

const Employees = () => {
    const { employeeKpis, loadingEmployee } = useContext(DataContext);
    const [periods, setPeriods] = useState([]);
    const [selectedPeriodIndex, setSelectedPeriodIndex] = useState(0);

    useEffect(() => {
        const fetchPeriods = async () => {
            const kpisCollection = collection(firestore, 'employee_kpis');
            const kpisSnapshot = await getDocs(kpisCollection);

            // Extract week_summary fields and ensure unique values
            const weekSummaries = Array.from(new Set(
                kpisSnapshot.docs.map(doc => doc.data().week_summary)
                    .filter(summary => summary !== undefined) // Remove undefined entries
            ));

            // Sort by the first date found in each week_summary
            weekSummaries.sort((a, b) => {
                // Extract first date from each string
                const dateA = new Date(a.match(/\[(\d{1,2}), (\d{1,2}), (\d{4})\]/).slice(1).join('-'));
                const dateB = new Date(b.match(/\[(\d{1,2}), (\d{1,2}), (\d{4})\]/).slice(1).join('-'));

                return dateB - dateA; // Sort in descending order
            });

            setPeriods(weekSummaries); // Set unique periods array
            setSelectedPeriodIndex(0); // Set to most recent period by default
        };

        fetchPeriods();
    }, []);


    const handlePeriodChange = (direction) => {
        setSelectedPeriodIndex(prevIndex => {
            const newIndex = prevIndex + direction;
            if (newIndex >= 0 && newIndex < periods.length) {
                return newIndex;
            }
            return prevIndex;
        });
    };

    if (loadingEmployee) {
        return <p>Loading Employee Data...</p>;
    }
    const columnFormatDict = {
        "employee": { "name": "Employee", "pos": 1, "width": 2 },
        "est_comm_per_hour": { "name": "Est. Comm / Hour", "type": "currency", "pos": 10, "just": "right" },
        "est_commission": { "name": "Est. Commission", "type": "currency", "pos": 11, "just": "left" },

        "sales_comm": { "name": "Sales Comm", "type": "currency", "pos": 4 },
        "sales_per_hour": { "name": "Sales / Hour", "type": "percentage", "pos": 5 },
        "sales_bonus": { "name": "Sales Bonus", "type": "currency", "pos": 6 },
        "repair_comm": { "name": "Repair Comm", "type": "currency", "pos": 7 },
        "repairs_per_hour": { "name": "Repairs / Hour", "type": "percentage", "pos": 8 },
        "repair_bonus": { "name": "Repair Bonus", "type": "currency", "skip": true },
        "hours": { "name": "Hours", "type": "float", "pos": 30 },

        "gp_per_hour": { "name": "GP / Hour", "type": "currency", "pos": 2, "just": "right" },
        "total_gp": { "name": "Total GP", "type": "currency", "pos": 3, "just": "right" },

        "sales": { "name": "Sales", "type": "int", "pos": 13, "just": "center" },
        "repairs": { "name": "Repairs", "type": "int", "pos": 14, "just": "center" },
        "cogs": { "name": "COGS", "type": "currency", "pos": 15, "just": "right", "skip": true },
        "cogs_percent": { "name": "COGS %", "type": "percentage", "pos": 16, "just": "left", "skip": true },
        "discounts": { "name": "Discounts", "type": "currency", "pos": 17, "just": "right", "skip": true },
        "discount_percent": { "name": "Discount %", "type": "percentage", "pos": 18, "just": "left", "skip": true },
        "phone_repairs": { "name": "Phone (R)", "type": "int", "pos": 19, "just": "left", "skip": true },
        "tablet_repairs": { "name": "Tablet (R)", "type": "int", "pos": 20, "just": "left", "skip": true },
        "solder_repairs": { "name": "Solder (R)", "type": "int", "pos": 21, "just": "left", "skip": true },
        "other_repairs": { "name": "Other (R)", "type": "int", "pos": 22, "just": "left", "skip": true },
        "cpo_sales": { "name": "CPO (S)", "type": "int", "pos": 23, "just": "left" },
        "protection_sales": { "name": "Protection (S)", "type": "int", "pos": 24, "just": "left" },
        "accessories_sales": { "name": "Accessories (S)", "type": "int", "pos": 25, "just": "left" },

        "total_sales": { "name": "Total Sales", "type": "currency", "pos": 12, "just": "left", "skip": true },
        "week_summary": { "type": "currency", "skip": true },
    };
    return (
        <div className="employees">
            <header className="dashboard-header">
                <h1>Employee KPIs</h1>
            </header>
            <div className="dashboard-content">
                <div className="button-container">
                    <div className="period">Current {periods[selectedPeriodIndex]} </div>
                    <button onClick={() => handlePeriodChange(-1)} disabled={selectedPeriodIndex <= 0}>⬅️</button>
                    <button onClick={() => handlePeriodChange(1)} disabled={selectedPeriodIndex >= periods.length - 1}>➡️</button>
                </div>
                <TableViewer
                    dataKey={employeeKpis}
                    colFormatDict={columnFormatDict}
                    timestamp={periods[selectedPeriodIndex]}
                />
            </div>
        </div>
    );

};

export default Employees;
