const themes = {
    // “qf” is our sleek modern office theme – minimal radii, crisp edges.
    'qf-light': {
        '--background-color': '#f5f5f5',
        '--primary-color': 'crimson',
        '--primary-hover-color': 'darkred',
        '--text-color': '#333',
        '--sidebar-bg-color': '#1a1a1a',
        '--sidebar-text-color': 'white',
        '--button-bg-color': 'crimson',
        '--button-hover-color': 'darkred',
        '--popup-bg-color': 'white',
        '--form-border-color': '#ddd',
        '--border-radius': '4px',
        '--box-shadow': '0 2px 4px rgba(0, 0, 0, 0.1)',
        '--table-bg-color': '#ffffff',
    },

    'qf-dark': {
        '--background-color': '#1a1a1a',
        '--primary-color': 'crimson',
        '--primary-hover-color': 'darkred',
        '--text-color': 'white',
        '--sidebar-bg-color': '#121212',
        '--sidebar-text-color': '#e0e0e0',
        '--button-bg-color': 'crimson',
        '--button-hover-color': 'darkred',
        '--popup-bg-color': '#2c2c2c',
        '--form-border-color': '#444',
        '--border-radius': '4px',
        '--box-shadow': '0 2px 4px rgba(0, 0, 0, 0.2)',
        '--table-bg-color': '#2c2c2c',
    },

    // “bl” is our mystical, soft-blue theme – with more rounded corners and deeper shadows. 
    'bl-light': {
        '--background-color': '#e0f7fa',
        '--primary-color': '#007bb5',
        '--primary-hover-color': '#005f8c',
        '--text-color': '#004d60',
        '--sidebar-bg-color': '#004c7a',
        '--sidebar-text-color': '#ffffff',
        '--button-bg-color': '#007bb5',
        '--button-hover-color': '#005f8c',
        '--popup-bg-color': '#ffffff',
        '--form-border-color': '#aad4e4',
        '--border-radius': '12px',
        '--box-shadow': '0 4px 8px rgba(0, 0, 0, 0.15)',
        '--table-bg-color': '#f0fbff',
    },

    'bl-dark': {
        '--background-color': '#1e1e1e',
        '--primary-color': '#007bb5',
        '--primary-hover-color': '#005f8c',
        '--text-color': 'white',
        '--sidebar-bg-color': '#121212',
        '--sidebar-text-color': '#e0e0e0',
        '--button-bg-color': '#007bb5',
        '--button-hover-color': '#005f8c',
        '--popup-bg-color': '#2c2c2c',
        '--form-border-color': '#444',
        '--border-radius': '12px',
        '--box-shadow': '0 4px 8px rgba(0, 0, 0, 0.3)',
        '--table-bg-color': '#2a2a2a',
    },

    // “gr” is our Matrix-inspired “circuit” theme – neon lime on black with sharp edges. 
    'gr-light': {
        '--background-color': '#1a1a1a',
        '--primary-color': '#39ff14',
        '--primary-hover-color': '#2ecc0f',
        '--text-color': '#d4d4d4',
        '--sidebar-bg-color': '#0f0f0f',
        '--sidebar-text-color': '#39ff14',
        '--button-bg-color': '#39ff14',
        '--button-hover-color': '#2ecc0f',
        '--popup-bg-color': '#333333',
        '--form-border-color': '#555555',
        '--border-radius': '0px',
        '--box-shadow': '0 2px 4px rgba(0, 255, 0, 0.2)',
        '--table-bg-color': '#1a1a1a',
    },

    'gr-dark': {
        '--background-color': '#000000',
        '--primary-color': '#39ff14',
        '--primary-hover-color': '#2ecc0f',
        '--text-color': '#00ff00',
        '--sidebar-bg-color': '#0a0a0a',
        '--sidebar-text-color': '#39ff14',
        '--button-bg-color': '#39ff14',
        '--button-hover-color': '#2ecc0f',
        '--popup-bg-color': '#111111',
        '--form-border-color': '#333333',
        '--border-radius': '0px',
        '--box-shadow': '0 2px 4px rgba(0, 255, 0, 0.3)',
        '--table-bg-color': '#000000',
    },
};

export default themes;