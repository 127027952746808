import React, { useContext, useState, useEffect } from 'react';
import { DataContext } from '../components/DataContext';
import '../styles/Locations.css';

const Locations = () => {
    const { locationKpis, loadingLocation } = useContext(DataContext);

    // Filter states
    const [selectedRange, setSelectedRange] = useState(30); // Default: last 30 days
    const [selectedMonth, setSelectedMonth] = useState(''); // Format: "YYYY-MM"
    const [customStartDate, setCustomStartDate] = useState('');
    const [customEndDate, setCustomEndDate] = useState('');

    // Available months extracted from locationKpis
    const [availableMonths, setAvailableMonths] = useState([]);

    // Extract available months from the data
    useEffect(() => {
        if (!locationKpis) return;
        const monthsSet = new Set();
        locationKpis.forEach(record => {
            if (record.date) {
                const dateObj = new Date(record.date);
                if (!isNaN(dateObj)) {
                    const monthStr = `${dateObj.getFullYear()}-${('0' + (dateObj.getMonth() + 1)).slice(-2)}`;
                    monthsSet.add(monthStr);
                }
            }
        });
        const monthsArr = Array.from(monthsSet).sort().reverse(); // Most recent first
        setAvailableMonths(monthsArr);
    }, [locationKpis]);

    // Apply filters to the raw locationKpis data
    const filteredRecords = locationKpis
        ? locationKpis.filter(record => {
            if (!record.date) return false;
            const recordDate = new Date(record.date);
            if (isNaN(recordDate)) return false;

            let include = true;
            // Last X days filter
            if (selectedRange) {
                const cutoff = new Date(Date.now() - selectedRange * 24 * 60 * 60 * 1000);
                if (recordDate < cutoff) include = false;
            }
            // Month filter
            if (selectedMonth) {
                const recordMonth = `${recordDate.getFullYear()}-${('0' + (recordDate.getMonth() + 1)).slice(-2)}`;
                if (recordMonth !== selectedMonth) include = false;
            }
            // Custom date range filters
            if (customStartDate) {
                const startDate = new Date(customStartDate);
                if (recordDate < startDate) include = false;
            }
            if (customEndDate) {
                const endDate = new Date(customEndDate);
                if (recordDate > endDate) include = false;
            }
            return include;
        })
        : [];

    // Group filtered records by location
    const groupedByLocation = filteredRecords.reduce((acc, record) => {
        const location = record.location || 'Unknown Location';
        if (!acc[location]) {
            acc[location] = [];
        }
        acc[location].push(record);
        return acc;
    }, {});

    // Helper to aggregate KPIs for an array of records
    const aggregateKpis = (records) => {
        let totalSalesBonus = 0;
        let totalTotalGp = 0;
        let totalEstComm = 0;
        let totalSales = 0;
        let totalRepairs = 0;
        let totalHours = 0;
        const count = records.length;

        records.forEach(record => {
            totalSalesBonus += record.sales_bonus || 0;
            totalTotalGp += record.total_gp || 0;
            totalEstComm += record.est_commission || 0;
            totalSales += record.sales || 0;
            totalRepairs += record.repairs || 0;
            totalHours += record.hours || 0;
        });

        const salesPerHour = totalHours > 0 ? totalSales / totalHours : 0;
        const gpPerHour = totalHours > 0 ? totalTotalGp / totalHours : 0;
        const avgSalesBonus = count > 0 ? totalSalesBonus / count : 0;
        const avgTotalGp = count > 0 ? totalTotalGp / count : 0;
        const avgEstComm = count > 0 ? totalEstComm / count : 0;

        return {
            count,
            salesPerHour,
            gpPerHour,
            avgSalesBonus,
            avgTotalGp,
            avgEstComm,
            totalSales,
            totalRepairs,
            totalHours,
        };
    };

    // Company-wide aggregation over all filtered records
    const companyWideAggregate = aggregateKpis(filteredRecords);

    // Function to reset filters to defaults
    const resetFilters = () => {
        setSelectedRange(30);
        setSelectedMonth('');
        setCustomStartDate('');
        setCustomEndDate('');
    };

    if (loadingLocation) {
        return <p>Loading Location Data...</p>;
    }

    return (
        <div className="locations">
            <header className="dashboard-header">
                <h1>Location KPIs</h1>
            </header>

            <div className="filters">
                <div className="filter-group">
                    <span>Last Days:</span>
                    {[30, 60, 90].map(range => (
                        <button
                            key={range}
                            className={selectedRange === range ? 'active' : ''}
                            onClick={() => setSelectedRange(range)}
                        >
                            {range} Days
                        </button>
                    ))}
                </div>

                <div className="filter-group">
                    <label>
                        Month:
                        <select value={selectedMonth} onChange={e => setSelectedMonth(e.target.value)}>
                            <option value="">All</option>
                            {availableMonths.map(month => (
                                <option key={month} value={month}>
                                    {month}
                                </option>
                            ))}
                        </select>
                    </label>
                </div>

                <div className="filter-group">
                    <label>
                        Start Date:
                        <input
                            type="date"
                            value={customStartDate}
                            onChange={e => setCustomStartDate(e.target.value)}
                        />
                    </label>
                    <label>
                        End Date:
                        <input
                            type="date"
                            value={customEndDate}
                            onChange={e => setCustomEndDate(e.target.value)}
                        />
                    </label>
                </div>

                <button onClick={resetFilters}>Reset Filters</button>
            </div>

            <div className="cards-container">
                {/* Company Wide Card */}
                <div className="location-card company-wide">
                    <h2>Company Wide</h2>
                    <p>
                        <strong>Days Count:</strong> {companyWideAggregate.count}
                    </p>
                    <p>
                        <strong>Sales / Hour:</strong> {companyWideAggregate.salesPerHour.toFixed(2)}
                    </p>
                    <p>
                        <strong>GP / Hour:</strong> {companyWideAggregate.gpPerHour.toFixed(2)}
                    </p>
                    <p>
                        <strong>Avg Sales Bonus:</strong> ${companyWideAggregate.avgSalesBonus.toFixed(2)}
                    </p>
                    <p>
                        <strong>Avg Total GP:</strong> ${companyWideAggregate.avgTotalGp.toFixed(2)}
                    </p>
                    <p>
                        <strong>Avg Est. Commission:</strong> ${companyWideAggregate.avgEstComm.toFixed(2)}
                    </p>
                    <p>
                        <strong>Total Sales:</strong> {companyWideAggregate.totalSales}
                    </p>
                    <p>
                        <strong>Total Repairs:</strong> {companyWideAggregate.totalRepairs}
                    </p>
                </div>

                {/* Location-specific Cards */}
                {Object.keys(groupedByLocation).map(location => {
                    const aggregate = aggregateKpis(groupedByLocation[location]);
                    return (
                        <div className="location-card" key={location}>
                            <h2>{location}</h2>
                            <p>
                                <strong>Days Count:</strong> {aggregate.count}
                            </p>
                            <p>
                                <strong>Sales / Hour:</strong> {aggregate.salesPerHour.toFixed(2)}
                            </p>
                            <p>
                                <strong>GP / Hour:</strong> {aggregate.gpPerHour.toFixed(2)}
                            </p>
                            <p>
                                <strong>Avg Sales Bonus:</strong> ${aggregate.avgSalesBonus.toFixed(2)}
                            </p>
                            <p>
                                <strong>Avg Total GP:</strong> ${aggregate.avgTotalGp.toFixed(2)}
                            </p>
                            <p>
                                <strong>Avg Est. Commission:</strong> ${aggregate.avgEstComm.toFixed(2)}
                            </p>
                            <p>
                                <strong>Total Sales:</strong> {aggregate.totalSales}
                            </p>
                            <p>
                                <strong>Total Repairs:</strong> {aggregate.totalRepairs}
                            </p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Locations;
