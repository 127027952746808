import React, { useContext, useEffect, useState } from 'react';
import { DataContext } from '../components/DataContext';
import { collection, getDocs } from 'firebase/firestore';
import { firestore } from '../utils/firebaseConfig';
import '../styles/Managers.css';

/**
 * extractEndDate:
 *   Given a string like "Period: [2, 9, 2025] to [2, 15, 2025]",
 *   returns a Date for the second bracket (the end date).
 */
const extractEndDate = (weekString) => {
    if (!weekString) return new Date('1970-01-01');
    const cleaned = weekString.replace('Period: ', '');
    const match = cleaned.match(/\[(\d+), (\d+), (\d+)\] to \[(\d+), (\d+), (\d+)\]/);
    if (!match) return new Date('1970-01-01');
    const [, , , , m2, d2, y2] = match;
    return new Date(`${m2}/${d2}/${y2}`);
};

const Managers = () => {
    const { employeeKpis, loadingEmployee } = useContext(DataContext);

    // We'll store the list of *all employees* found in `employee_kpis`
    const [allEmployees, setAllEmployees] = useState([]);
    // Which employees are currently visible
    const [visibleEmployees, setVisibleEmployees] = useState([]);
    // Single range for all employees: 30/60/90
    const [selectedRange, setSelectedRange] = useState(30);

    // For controlling the employee selector modal
    const [showModal, setShowModal] = useState(false);

    // We'll track whether we've already done our "default" selection for employees with data
    // so we don't overwrite user changes if they open the modal again.
    const [didDefaultSelection, setDidDefaultSelection] = useState(false);

    // On mount, fetch a unique list of employees from the employee_kpis collection
    useEffect(() => {
        const fetchAllEmployees = async () => {
            const kpisCollection = collection(firestore, 'employee_kpis');
            const snapshot = await getDocs(kpisCollection);

            const uniqueNames = new Set();
            snapshot.forEach((doc) => {
                const data = doc.data();
                if (data.employee) {
                    uniqueNames.add(data.employee);
                }
            });
            const employeesArr = Array.from(uniqueNames).sort(); // Sort alphabetically
            setAllEmployees(employeesArr);

            // Temporarily set visible to everyone—will refine in the next effect
            setVisibleEmployees(employeesArr);
        };

        fetchAllEmployees();
    }, []);

    /**
     * In a second effect, once we have both:
     *   1) allEmployees
     *   2) the employeeKpis from DataContext
     * we can default to hiding employees who have no data in the last 30 days.
     */
    useEffect(() => {
        if (didDefaultSelection) return; // only do this once
        if (!employeeKpis || employeeKpis.length === 0) return;
        if (allEmployees.length === 0) return;

        const now = new Date();
        const thirtyDaysMillis = 30 * 24 * 60 * 60 * 1000;

        // Build a set of employees who have data in the last 30 days
        const employeesWithRecentData = new Set();
        for (const kpi of employeeKpis) {
            if (!kpi.week_summary || !kpi.employee) continue;
            const endDate = extractEndDate(kpi.week_summary);
            const diff = now - endDate;
            // If it's within the last 30 days
            if (diff <= thirtyDaysMillis && diff >= 0) {
                employeesWithRecentData.add(kpi.employee);
            }
        }

        // Filter allEmployees to only those who appear in employeesWithRecentData
        const defaultVisible = allEmployees.filter((emp) => employeesWithRecentData.has(emp));
        setVisibleEmployees(defaultVisible);
        setDidDefaultSelection(true);
    }, [employeeKpis, allEmployees, didDefaultSelection]);

    /**
     * computeAveragesForEmployee:
     *   For the given employee, compute the “Position, Sales/Hr, etc.” fields
     *   over the last X days (selectedRange).
     */
    const computeAveragesForEmployee = (employeeName) => {
        if (!employeeKpis || !employeeName) return null;

        const now = new Date();
        const rangeMillis = selectedRange * 24 * 60 * 60 * 1000;

        // Filter to only that employee's data in the last X days
        const relevantKpis = employeeKpis.filter((item) => {
            if (item.employee !== employeeName) return false;
            if (!item.week_summary) return false;
            const endDate = extractEndDate(item.week_summary);
            const diff = now - endDate;
            return diff <= rangeMillis && diff >= 0;
        });

        if (!relevantKpis.length) return null;

        let sumSalesBonus = 0;
        let sumTotalGp = 0;
        let sumEstComm = 0;
        let sumSalesCount = 0;
        let sumRepairsCount = 0;
        let sumHours = 0;

        // For each relevant KPI, find that week's rank (position) by Sales/Hour
        relevantKpis.forEach((kpi) => {
            sumSalesBonus += kpi.sales_bonus || 0;
            sumTotalGp += kpi.total_gp || 0;
            sumEstComm += kpi.est_commission || 0;
            sumSalesCount += kpi.sales || 0;
            sumRepairsCount += kpi.repairs || 0;
            sumHours += kpi.hours || 0;

        });

        const count = relevantKpis.length;
        // Weighted approach for GP/Hr and Sales/Hr
        const gpPerHour = sumHours > 0 ? sumTotalGp / sumHours : 0;
        const salesPerHour = sumHours > 0 ? sumSalesCount / sumHours : 0;
        const avgSalesBonus = sumSalesBonus / count;
        const avgTotalGp = sumTotalGp / count;
        const avgEstComm = sumEstComm / count;
        const avgSalesCount = sumSalesCount / count;
        const avgRepairsCount = sumRepairsCount / count;

        return {
            employeeName,
            salesPerHour,
            avgSalesBonus,
            gpPerHour,
            avgTotalGp,
            avgEstComm,
            avgSalesCount,
            avgRepairsCount,
        };
    };

    // We map over visibleEmployees to produce a card for each
    const renderEmployeeCard = (employeeName) => {
        const data = computeAveragesForEmployee(employeeName);
        if (!data) {
            return (
                <div className="manager-card" key={employeeName}>
                    <h2>{employeeName}</h2>
                    <p>No data found for last {selectedRange} days.</p>
                </div>
            );
        }

        const {
            salesPerHour,
            avgSalesBonus,
            gpPerHour,
            avgTotalGp,
            avgEstComm,
            avgSalesCount,
            avgRepairsCount,
        } = data;

        return (
            <div className="manager-card" key={employeeName}>
                <h2>{employeeName}</h2>
                <p>
                    <strong>Sales / Hr:</strong>{' '}
                    {Number.isFinite(salesPerHour) ? salesPerHour.toFixed(2) : 'N/A'}
                </p>
                <p>
                    <strong>Sales Bonus:</strong>{' '}
                    {Number.isFinite(avgSalesBonus) ? `$${avgSalesBonus.toFixed(2)}` : 'N/A'}
                </p>
                <p>
                    <strong>GP / Hr:</strong>{' '}
                    {Number.isFinite(gpPerHour) ? `$${gpPerHour.toFixed(2)}` : 'N/A'}
                </p>
                <p>
                    <strong>Total GP:</strong>{' '}
                    {Number.isFinite(avgTotalGp) ? `$${avgTotalGp.toFixed(2)}` : 'N/A'}
                </p>
                <p>
                    <strong>Est. Commission:</strong>{' '}
                    {Number.isFinite(avgEstComm) ? `$${avgEstComm.toFixed(2)}` : 'N/A'}
                </p>
                <p>
                    <strong># of Sales:</strong>{' '}
                    {Number.isFinite(avgSalesCount) ? avgSalesCount.toFixed(2) : 'N/A'}
                </p>
                <p>
                    <strong># of Repairs:</strong>{' '}
                    {Number.isFinite(avgRepairsCount) ? avgRepairsCount.toFixed(2) : 'N/A'}
                </p>
            </div>
        );
    };

    // Range selector for all employees
    const renderRangeSelector = () => (
        <div className="range-selector">
            {[30, 60, 90].map((range) => (
                <button
                    key={range}
                    className={range === selectedRange ? 'active' : ''}
                    onClick={() => setSelectedRange(range)}
                >
                    {range} Days
                </button>
            ))}
        </div>
    );

    // Modal form submission => close
    const handleModalSubmit = (e) => {
        e.preventDefault();
        setShowModal(false);
    };

    // Restore "visibleEmployees" to only those with data in the past 30 days
    const handleDefault = () => {
        if (!employeeKpis || allEmployees.length === 0) return;
        const now = new Date();
        const thirtyDaysMillis = 30 * 24 * 60 * 60 * 1000;
        const employeesWithRecentData = new Set();
        for (const kpi of employeeKpis) {
            if (!kpi.week_summary || !kpi.employee) continue;
            const endDate = extractEndDate(kpi.week_summary);
            const diff = now - endDate;
            if (diff <= thirtyDaysMillis && diff >= 0) {
                employeesWithRecentData.add(kpi.employee);
            }
        }
        const defaultVisible = allEmployees.filter((emp) => employeesWithRecentData.has(emp));
        setVisibleEmployees(defaultVisible);
    };

    // Renders the "Select Employees" modal
    const renderEmployeeSelectorModal = () => {
        if (!showModal) return null;

        return (
            <div className="modal-overlay" onClick={() => setShowModal(false)}>
                <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                    <div className="popup-header">
                        <h3>Select Employees</h3>
                        <button className="close-button" onClick={() => setShowModal(false)}>
                            ×
                        </button>
                    </div>
                    <form onSubmit={handleModalSubmit}>
                        <div className="modal-columns-grid">
                            {allEmployees.map((emp) => {
                                const selected = visibleEmployees.includes(emp);
                                return (
                                    <div
                                        key={emp}
                                        className={`modal-grid-item ${selected ? 'selected' : ''}`}
                                        onClick={() => {
                                            if (selected) {
                                                setVisibleEmployees(visibleEmployees.filter((x) => x !== emp));
                                            } else {
                                                setVisibleEmployees([...visibleEmployees, emp]);
                                            }
                                        }}
                                    >
                                        {emp}
                                    </div>
                                );
                            })}
                        </div>
                        <div className="modal-buttons">
                            <button type="button" onClick={handleDefault}>
                                Default
                            </button>
                            <button type="submit">Save</button>
                        </div>
                    </form>
                </div>
            </div>
        );
    };

    return (
        <div className="managers-container">
            <h1>Manager Dashboard</h1>

            {loadingEmployee ? (
                <p>Loading Employee Data...</p>
            ) : (
                <>
                    {/* Range selector for all employees */}
                    {renderRangeSelector()}

                    {/* Button to open the "Select Employees" modal */}
                    <button
                        className="employee-selector-button"
                        onClick={() => setShowModal(true)}
                    >
                        Select Employees
                    </button>

                    {/* The modal, if open */}
                    {renderEmployeeSelectorModal()}

                    {/* Grid of manager "cards" for each visible employee */}
                    <div className="manager-cards-grid">
                        {visibleEmployees.map((emp) => renderEmployeeCard(emp))}
                    </div>
                </>
            )}
        </div>
    );
};

export default Managers;
