import React from 'react';
import '../styles/Sidebar.css';

const Sidebar = ({ userInfo, openRegistrationPopup, handleNav, handleLogOut }) => {
    return (
        <aside className="sidebar">
            <nav className="nav">
                <button onClick={() => handleNav('/dashboard')}>Home</button>
                <button onClick={() => handleNav('/employees')}>Employees</button>
                <button onClick={() => handleNav('/locations')}>Locations</button>

                {/* Only show Managers button if userInfo.manager is true */}
                {userInfo?.manager && (
                    <button onClick={() => handleNav('/managers')}>Managers</button>
                )}

                <button onClick={() => handleNav('/sandbox')}>Sandbox</button>
            </nav>
            <div>
                {userInfo && (
                    <div className="user-info" onClick={openRegistrationPopup}>
                        <span>{userInfo.firstName} {userInfo.lastName}</span>
                    </div>
                )}
                <button className="out" onClick={handleLogOut}>Logout</button>
            </div>
        </aside>
    );
};

export default Sidebar;
